"use strict";
Class.register('App.Store.Brands');
App.Store.Brands = (function (base, $) {
    /* Private */
    var _load = function (self) {
        //handle letter click
        self.select('a[data-id]').click(function () {
            var id = self.select(this).attr('data-id');

            _scrollToBrand(self, id);
        });
    };

    var _scrollToBrand = function (self, id) {
        var top = self.select('#' + id).position().top;

        $('html,body').scrollTop(top);
    };

    /* Public */
    var self = function (selector) {
        base.call(this, selector);
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);
Class.register('App.Store.BuyerInfo');
App.Store.BuyerInfo = (function (base, $) {
    /* Private */
    var _load = function (self) {
        self.select('.btn-reserve').click(function () {
            _submitReservation(self);
        });

        self.select('.link-phone-prefixes').click(function () {
            self.select('.modal-phone-prefixes').modal();
        });

        _initBuyerInfo(self);
    };

    var _submitReservation = function (self) {
        if (!App.Context.getSessionId()) {
            alert(App.Context.getResource('Ju kemi fshirë ose çaktivizuar cookies. Ju lutem vendosini artikujt në shportë nga e para.', 'You have deleted or disabled cookies. Please add the items to the shopping cart once more.'));
        } else {
            self.select('.buyer-info .notes').notes('clear');

            var errors = new Array();

            var buyerInfo = _getBuyerInfo(self);

            var validator = App.Validator;

            if (validator.isEmpty(buyerInfo.firstName)) {
                errors.push(App.Context.getResource('Emri duhet vendosur.', 'First name is required.'));
            }

            if (validator.isEmpty(buyerInfo.lastName)) {
                errors.push(App.Context.getResource('Mbiemri duhet vendosur.', 'Last name is required.'));
            }

            if (validator.isEmpty(buyerInfo.phone)) {
                errors.push(App.Context.getResource('Telefoni duhet vendosur.', 'Phone is required.'));
            } else if (!validator.isPhone(buyerInfo.phone)) {
                errors.push(App.Context.getResource('Telefoni nuk është në formatin e duhur. Ju lutem përdorni numrat (0-9) për të vendosur numrin e telefonit. Për numrat ndërkombëtar mund të përdorni + para kodit të shtetit.', 'The phone format is invalid. Please use digits (0-9) to enter the phone number. For international numbers you can use + before the country code.'));
            }

            if (!validator.isEmpty(buyerInfo.email) && !validator.isEmail(buyerInfo.email)) {
                errors.push(App.Context.getResource('Formati i email-it është i gabuar.', 'The email address must be valid.'));
            }

            if (errors.length) {
                self.select('.buyer-info .notes').notes('confirm', errors);

                $('html,body').scrollTop($('#main-container').position().top);
            } else {
                App.Api.call({
                    url: '/api/store/salesorder/submit',
                    parameters: {
                        firstName: buyerInfo.firstName,
                        lastName: buyerInfo.lastName,
                        phone: buyerInfo.phone,
                        email: buyerInfo.email,
                        subscribeEmail: buyerInfo.subscribeEmail,
                        shippingOption: App.ShippingOption.Store
                    },
                    resultHandler: function (result) {
                        if (!result.OrderNumber) {
                            errors.push(App.Context.getResource('Disa nga artikujt në shportë sapo janë rezervuar ose shitur. Ju do ri-dërgoheni tek shporta për ta përditësuar.', 'Some of the items on the cart have been reserved or sold. You will be sent to the shopping cart to update the list of items.'));

                            self.select('.buyer-info .notes').notes('confirm', errors);

                            $('html,body').scrollTop($('#main-container').position().top);

                            setTimeout(function () {
                                location.href = '/store/cart';
                            }, 5000);
                        } else {
                            App.Context.setOrderNumber(result.OrderNumber);

                            if (App.Storage.isAvailable()) {
                                App.Storage.set('FirstName', buyerInfo.firstName);
                                App.Storage.set('LastName', buyerInfo.lastName);
                                App.Storage.set('Phone', buyerInfo.phone);
                                App.Storage.set('Email', buyerInfo.email);
                            }

                            location.href = '/store/reservationconfirmation';
                        }
                    }
                });
            }
        }
    };

    var _initBuyerInfo = function (self) {
        if (App.Storage.isAvailable()) {
            self.select('.buyer-info .first-name').val(App.Storage.get('FirstName'));
            self.select('.buyer-info .last-name').val(App.Storage.get('LastName'));
            self.select('.buyer-info .phone').val(App.Storage.get('Phone'));
            self.select('.buyer-info .email').val(App.Storage.get('Email'));
        }
    };

    var _getBuyerInfo = function (self) {
        return {
            firstName: self.select('.buyer-info .first-name').val().trim(),
            lastName: self.select('.buyer-info .last-name').val().trim(),
            phone: self.select('.buyer-info .phone').val().trim().replace(/\s/g, ''),
            email: self.select('.buyer-info .email').val().trim().toLowerCase(),
            subscribeEmail: self.select('.subscribe-email').is(':checked')
        };
    };

    /* Public */
    var self = function (selector) {
        base.call(this, selector);

        //check session id
        if (!App.Context.getSessionId()) {
            location.href = '/store/cart';
        }

        //check cart
        if (!App.Context.getCartCount()) {
            location.href = '/store/cart';
        }
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);
Class.register('App.Store.Cart');
App.Store.Cart = (function (base, $) {
    /* Private */
    var _load = function (self) {
        //delete from cart
        self.select().on('click', '.btn-delete', function () {
            if (!App.Context.getSessionId()) {
                alert(App.Context.getResource('Cookies duhen aktivizuar për të përditësuar shportën.', 'Cookies must be enabled to update the shopping cart.'));
            } else {
                var id = parseInt(self.select(this).attr('data-id'));
                _deleteItem(self, id);
            }
        });

        //buy in store
        self.select().on('click', '.btn-buy-in-store', function () {
            if (!App.Context.getSessionId()) {
                alert(App.Context.getResource('Cookies duhen aktivizuar për të rezervuar artikujt.', 'Cookies must be enabled to reserve items.'));
            } else {
                location.href = '/store/buyerinfo';
            }
        });

        //buy online
        self.select().on('click', '.btn-buy-online', function () {
            if (!App.Context.getSessionId()) {
                alert(App.Context.getResource('Cookies duhen aktivizuar për të përfunduar porosinë online.', 'Cookies must be enabled to complete the order.'));
            } else {
                location.href = '/store/shippingoptions';
            }
        });

        //continue shopping
        self.select().on('click', '.btn-continue-shopping', function () {
            location.href = '/';
        });

        //alert if cookies are disabled
        if (!App.Context.getSessionId()) {
            alert(App.Context.getResource('Cookies duhen aktivizuar për të shtuar artikuj në shportë.', 'Cookies must be enabled to add items to the shopping cart.'));
        }
    };

    var _loadItems = function (self) {
        App.Api.call({
            url: '/store/cartgrid',
            resultHandler: function (result) {
                //load html
                self.select('.cart-grid').html(result);

                //scroll top
                $('html,body').scrollTop($('#main-container').position().top);
            }
        });
    };

    var _deleteItem = function (self, id) {
        App.Api.call({
            url: '/api/store/cart/item/delete',
            parameters: {
                itemId: id
            },
            resultHandler: function (result) {
                App.EventManager.dispatchEvent('CartChanged', { Count: result.Count });

                _loadItems(self);

                var gtag = App.Lib.getGTag();
                if (gtag) {
                    gtag('event', 'user_deleted_item_from_cart');
                }
            }
        });
    };

    /* Public */
    var self = function (selector) {
        base.call(this, selector);
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);
Class.register('App.Store.Index');
App.Store.Index = (function (base, $) {
    /* Private */
    var _load = function (self) {
        //lazy load images
        self.select('.img-lazy').lazyload({
            threshold: 200
        });

        //init banners carousel
        self.select('.banners-carousel').owlCarousel({
            items: 1,
            lazyLoad: true,
            loop: true,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    nav: false
                },
                992: {
                    nav: true
                }
            }
        });

        //init instashop carousel
        self.select('.instashop-carousel').owlCarousel({
            dots: false,
            lazyLoad: true,
            loop: true,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            margin:8,
            responsive: {
                0: {
                    items: 2,
                    slideBy: 2,
                    nav: false
                },
                768: {
                    items: 4,
                    slideBy: 4,
                    nav: false
                },
                992: {
                    items: 6,
                    slideBy: 6,
                    nav: true
                }
            }
        });

        //display email subscription modal
        self.select('.btn-email-subscription').click(function () {
            _displayEmailSubscriptionModal(self);
        });

        //handle email subscription
        self.select('.modal-email-subscription .btn-subscribe').click(function () {
            _subscribeEmail(self);
        });

        var emailSubscriptionShown = App.Context.getEmailSubscriptionShown();
        if (!emailSubscriptionShown) {
            _displayEmailSubscriptionModal(self);
            App.Context.setEmailSubscriptionShown(true);
        }
    };

    var _subscribeEmail = function (self) {
        var subscription = _getEmailSubscription(self);

        var validator = App.Validator;
        
        if (validator.isEmpty(subscription.email)) {
            self.select('.modal-email-subscription .text').text(App.Context.getResource('Email-i duhet vendosur për tu regjistruar.', 'Please provide your email to register.'));
        } else if (!validator.isEmail(subscription.email)) {
            self.select('.modal-email-subscription .text').text(App.Context.getResource('Formati i email-it duhet të jetë i saktë për tu regjistruar.', 'Please provide a valid email address to register.'));
        } else {
            App.Api.call({
                url: '/api/store/email/subscribe',
                parameters: subscription,
                resultHandler: function () {
                    //show message
                    self.select('.modal-email-subscription .text').text(App.Context.getResource('Email-i juaj u regjistrua. Ju do të merrni së shpejti informacion rreth uljeve, ofertave speciale, dhe prurjeve të reja.', 'Your email was registered. You will soon receive information about our discounts, special offers, and new arrivals.'));

                    //save email
                    if (App.Storage.isAvailable()) {
                        App.Storage.set('Email', subscription.email);
                    }

                    //clear email
                    self.select('.modal-email-subscription .email').val('');

                    //send event
                    var gtag = App.Lib.getGTag();
                    if (gtag) {
                        gtag('event', 'user_subscribed_email');
                    }
                }
            });
        }
    };

    var _getEmailSubscription = function (self) {
        return {
            email: self.select('.modal-email-subscription .email').val()
        };
    };

    var _displayEmailSubscriptionModal = function (self) {
        self.select('.modal-email-subscription .text').text(App.Context.getResource('Regjistroni email-in tuaj për tu njoftuar nga ViaAnabel mbi uljet, ofertat speciale, dhe prurjet e reja.', 'Register your email to be notified by ViaAnabel about discounts, special offers, and new arrivals.'));
        if (App.Storage.isAvailable()) {
            self.select('.modal-email-subscription .email').val(App.Storage.get('Email'));
        }
        self.select('.modal-email-subscription').modal();
    };

    /* Public */
    var self = function (selector) {
        base.call(this, selector);
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);
Class.register('App.Store.Instashop');
App.Store.Instashop = (function (base, $) {
    /* Private */
    var _load = function (self) {
        //lazy load images
        self.select('.img-lazy').lazyload({
            threshold: 200
        });

        //switch to english
        self.select('.btn-view-english').click(function () {
            var url = self.select(this).attr('data-href');
            location.href = url;
        });

        //handle pagination
        self.select().on('click', '.item-pagination .previous-page', function () {
            //prevent users from scrolling beyond the first page
            if (self._page <= 0) {
                self._page = 0;
            } else {
                //decrease page index
                self._page -= 1;
            }

            //get url
            var url = _getUrl(self);

            //load url
            location.href = url;
        });

        self.select().on('click', '.item-pagination .next-page', function () {
            var pagesCount = parseInt(self.select(this).attr('data-pages-count'));

            //prevent users from scrolling beyond the last page
            if (self._page >= pagesCount - 1) {
                self._page = pagesCount - 1;
            } else {
                //increase page index
                self._page += 1;
            }

            //get url
            var url = _getUrl(self);

            //load url
            location.href = url;
        });
    
        //handle scroll to posts
        self.select().on('click', '.btn-scroll-top', function () {
            _scrollToPosts(self);
        });

        //parse url
        var page = App.Url.getParameter('page');

        self._page = parseInt(page) ? parseInt(page) : 0;
    };

    var _getUrl = function (self) {
        var url = '/store/instashop';

        if (self._page) {
            return url + '?page=' + self._page;
        } else {
            return url;
        }
    };

    var _scrollToPosts = function (self) {
        var top = self.select('.instashop-section').parent().position().top;

        $('html,body').scrollTop(top);
    };

    /* Public */
    var self = function (selector) {
        base.call(this, selector);
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);
Class.register('App.Store.Item');
App.Store.Item = (function (base, $) {
    /* Private */
    var _load = function (self) {
        //check for src changes in the main image
        var mainImageElement = document.querySelector('#main-image');

        var observer = new MutationObserver(function (mutations) {
            for (var i = 0; i < mutations.length; i++) {
                var mutation = mutations[i];
                if (mutation.type === 'attributes' && mutation.attributeName === 'src') {
                    //re-enable zoom
                    _enableZoom(self);
                }
            }
        });

        observer.observe(mainImageElement, {
            attributes: true,
            attributeOldValue: true
        });

        //lazy load images
        self.select('.img-lazy').lazyload({
            threshold: 200
        });

        //init product carousel
        self.select('.product-carousel').owlCarousel({
            items: 1,
            lazyLoad: true,
            loop: true
        });

        //init similar products carousel
        self.select('.similar-products-carousel').owlCarousel({
            lazyLoad: true,
            loop: true,
            responsive: {
                0: {
                    items: 2,
                    slideBy: 2,
                    nav: false
                },
                768: {
                    items: 3,
                    slideBy: 3,
                    nav: false
                },
                992: {
                    items: 4,
                    slideBy: 4,
                    nav: true
                }
            }
        });

        //enable zoom
        _enableZoom(self);

        $(window).resize(function () {
            //re-enable zoom
            _enableZoom(self);
        });

        //handle thumb selection
        self.select('.thumb').click(function(){
            _selectImage(self, this);
        });

        //select first thumb
        var thumb = self.select('.thumb')[0];
        $(thumb).addClass('selected');

        //display sizes modal
        self.select('.link-sizes').click(function () {
            self.select('.modal-sizes').modal();
        });

        //switch to english
        self.select('.btn-view-english').click(function () {
            var url = self.select(this).attr('data-href');
            location.href = url;
        });

        //add item to cart
        self.select('.btn-cart').click(function () {
            if (!App.Context.getSessionId()) {
                alert(App.Context.getResource('Cookies duhen aktivizuar për të shtuar artikuj në shportë.', 'Cookies must be enabled to add items to the cart.'));
            } else {
                var id = parseInt(self.select(this).attr('data-id'));
                _addToCart(self, id);
            }
        });

        //go to cart
        self.select('.btn-go-to-cart').click(function () {
            self.select('.modal-cart').modal('hide');

            location.href = '/store/cart';
        });

        //add item to wish list
        self.select('.btn-wish-list').click(function () {
            if (!App.Context.getSessionId()) {
                alert(App.Context.getResource('Cookies duhen aktivizuar për të shtuar artikuj në wish list.', 'Cookies must be enabled to add items to the wish list.'));
            } else {
                var id = parseInt(self.select(this).attr('data-id'));
                _addToWishList(self, id);
            }
        });

        //go to wish list
        self.select('.btn-go-to-wish-list').click(function () {
            self.select('.modal-wish-list').modal('hide');

            location.href = '/store/wishlist';
        });

        //display offer modal
        self.select('.btn-offer').click(function () {
            _displayOfferModal(self);
        });

        //submit offer
        self.select('.btn-submit-offer').click(function () {
            _submitOffer(self);
        });

        //display email notification modal
        self.select('.btn-email-notification').click(function () {
            _displayEmailNotificationModal(self);
        });

        //handle email notification subscription
        self.select('.modal-email-notification .btn-notify').click(function () {
            var id = parseInt(self.select(this).attr('data-id'));
            _subscribeEmail(self, id);
        });

        //parse url
        var sku = App.Url.getIdParameter();

        self._sku = sku;
    };

    var _enableZoom = function (self) {
        //remove zoom
        $('.zoomContainer').remove();
        self.select('#main-image').removeData('elevateZoom');
        self.select('#main-image').removeData('zoomImage');

        //add zoom for tablets and desktop
        if (!$('.navbar-toggle').is(':visible')) {
            self.select('#main-image').elevateZoom({
                responsive: true,
                borderSize: 1,
                borderColour: '#D9D9D9',
                lensBorder: 0,
                lensColour: '#CCC'
            });
        } else {
            //self.select('#main-image').elevateZoom({
            //    responsive: true,
            //    zoomType: 'inner'
            //});
        }
    };

    var _selectImage = function (self, elem) {
        //get source
        var src = $(elem).attr('data-src');

        //select thumb
        $('.thumb').removeClass('selected');
        $(elem).addClass('selected');

        //update main image
        $('#main-image').attr('src', src);
    };

    var _addToCart = function (self, id) {
        App.Api.call({
            url: '/api/store/cart/item/add',
            parameters: {
                itemId: id
            },
            resultHandler: function (result) {
                App.EventManager.dispatchEvent('CartChanged', { Count: result.Count });

                self.select('.modal-cart').modal();

                var gtag = App.Lib.getGTag();
                if (gtag) {
                    gtag('event', 'user_added_item_to_cart');
                }
            }
        });
    };

    var _addToWishList = function (self, id) {
        App.Api.call({
            url: '/api/store/wishlist/item/add',
            parameters: {
                itemId: id
            },
            resultHandler: function (result) {
                App.EventManager.dispatchEvent('WishListChanged', { Count: result.Count });

                self.select('.modal-wish-list').modal();

                var gtag = App.Lib.getGTag();
                if (gtag) {
                    gtag('event', 'user_added_item_to_wish_list');
                }
            }
        });
    };

    var _submitOffer = function (self) {
        self.select('.offer-form .notes').notes('clear');

        var errors = new Array();

        var offer = _getOffer(self);

        var validator = App.Validator;

        if (validator.isEmpty(offer.price)) {
            errors.push(App.Context.getResource('Çmimi duhet vendosur.', 'Price is required.'));
        } else if (!validator.isInt(offer.price)) {
            errors.push(App.Context.getResource('Çmimi duhet të jetë një numër i plotë.', 'The price must be an integer.'));
        } else if (parseInt(offer.price) <= 0) {
            errors.push(App.Context.getResource('Çmimi duhet te jetë një numër më i madh se zero.', 'The price must be greater than zero.'));
        } else {
            var price = parseInt(offer.price);
            var minPrice = offer.minPrice;

            if (minPrice && price < minPrice) {
                errors.push(App.Context.getResource('Çmimi nuk mund të jetë më i vogël se ' + '\u20AC' + ' ' + minPrice + '.', 'The price cannot be less than ' + '\u20AC' + ' ' + minPrice + '.'));
            }
        }

        if (validator.isEmpty(offer.name)) {
            errors.push(App.Context.getResource('Emri duhet vendosur.', 'Name is required.'));
        }

        if (validator.isEmpty(offer.phone)) {
            errors.push(App.Context.getResource('Telefoni duhet vendosur.', 'Phone is required.'));
        } else if (!validator.isPhone(offer.phone)) {
            errors.push(App.Context.getResource('Telefoni nuk është në formatin e duhur. Ju lutem përdorni numrat (0-9) për të vendosur numrin e telefonit. Për numrat ndërkombëtar mund të përdorni + para kodit të shtetit.', 'The phone format is invalid. Please use digits (0-9) to enter the phone number. For international numbers you can use + before the country code.'));
        }

        if (errors.length) {
            self.select('.offer-form .notes').notes('confirm', errors);
        } else {
            App.Api.call({
                url: '/api/store/item/offer/submit',
                parameters: {
                    sku: self._sku,
                    price: offer.price,
                    name: offer.name,
                    phone: offer.phone
                },
                resultHandler: function () {
                    //display confirm
                    _displayOfferConfirmation(self);

                    //save form data
                    if (App.Storage.isAvailable()) {
                        var parts = offer.name.split(/\s+/);
                        if (parts.length >= 1) {
                            App.Storage.set('FirstName', parts[0]);
                        }
                        if (parts.length >= 2) {
                            App.Storage.set('LastName', parts[1]);
                        }

                        App.Storage.set('Phone', offer.phone);
                    }

                    //send event
                    var gtag = App.Lib.getGTag();
                    if (gtag) {
                        gtag('event', 'user_submitted_item_offer');
                    }
                }
            });
        }
    };

    var _getOffer = function (self) {
        return {
            price: self.select('.offer-form .price').val().trim(),
            minPrice: parseInt(self.select('.offer-form .price').attr('data-min-price')),
            name: self.select('.offer-form .name').val().trim(),
            phone: self.select('.offer-form .phone').val().trim().replace(/\s/g, '')
        };
    };

    var _displayOfferConfirmation = function (self) {
        self.select(".modal-offer .offer-confirmation").show();
        self.select(".modal-offer .offer-form").hide();
        self.select(".modal-offer .btn-submit-offer").hide();
    };

    var _displayOfferModal = function (self) {
        self.select(".modal-offer .offer-confirmation").hide();
        self.select(".modal-offer .offer-form").show();
        self.select(".modal-offer .btn-submit-offer").show();

        self.select('.offer-form .notes').notes('clear');
        self.select('.offer-form .price').val('');

        if (App.Storage.isAvailable()) {
            var name;
            if (App.Storage.get('FirstName')) {
                name = App.Storage.get('FirstName');
            }
            if (name && App.Storage.get('LastName')) {
                name = name + ' ' + App.Storage.get('LastName');
            }

            self.select('.offer-form .name').val(name);
            self.select('.offer-form .phone').val(App.Storage.get('Phone'));
        } else {
            self.select('.offer-form .name').val('');
            self.select('.offer-form .phone').val('');
        }

        self.select('.modal-offer').modal();
    };

    var _subscribeEmail = function (self, id) {
        var notification = _getEmailNotification(self);

        var validator = App.Validator;

        if (validator.isEmpty(notification.email)) {
            self.select('.modal-email-notification .text').text(App.Context.getResource('Email-i duhet vendosur për tu regjistruar.', 'Please provide your email to register.'));
        } else if (!validator.isEmail(notification.email)) {
            self.select('.modal-email-notification .text').text(App.Context.getResource('Formati i email-it duhet të jetë i saktë për tu regjistruar.', 'Please provide a valid email address to register.'));
        } else {
            App.Api.call({
                url: '/api/store/item/notification/subscribe',
                parameters: {
                    itemId: id,
                    email: notification.email
                },
                resultHandler: function () {
                    //show message
                    self.select('.modal-email-notification .text').text(App.Context.getResource('Email-i juaj u regjistrua. Ju do të merrni njoftime rreth këtij artikulli.', 'Your email was registered. You will receive notifications about this item.'));

                    //save email
                    if (App.Storage.isAvailable()) {
                        App.Storage.set('Email', notification.email);
                    }

                    //clear email
                    self.select('.modal-email-notification .email').val('');

                    //send event
                    var gtag = App.Lib.getGTag();
                    if (gtag) {
                        gtag('event', 'user_subscribed_to_item_notification');
                    }
                }
            });
        }
    };

    var _getEmailNotification = function (self) {
        return {
            email: self.select('.modal-email-notification .email').val()
        };
    };

    var _displayEmailNotificationModal = function (self) {
        self.select('.modal-email-notification .text').text(App.Context.getResource('Regjistroni email-in tuaj për tu njoftuar nëse ky artikull kthehet në shitje.', 'Register your email to be notified if this item is on sale again.'));
        if (App.Storage.isAvailable()) {
            self.select('.modal-email-notification .email').val(App.Storage.get('Email'));
        }
        self.select('.modal-email-notification').modal();
    };

    /* Public */
    var self = function (selector) {
        base.call(this, selector);
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);
Class.register('App.Store.Items');
App.Store.Items = (function (base, $) {
    /* Private */
    var _load = function (self) {
        //revert the security changes in jQuery 3.5+
        //the malihu custom scrollbar contains invalid html, such as <div /> and does not work with jQuery 3.5+
        var rxhtmlTag = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([a-z][^\/\0>\x20\t\r\n\f]*)[^>]*)\/>/gi;
        $.htmlPrefilter = function (html) {
            return html.replace(rxhtmlTag, "<$1></$2>");
        };

        //init custom scrollbar
        self.select('.brand-list').mCustomScrollbar();

        //lazy load images
        self.select('.img-lazy').lazyload({
            threshold: 200
        });

        //handle filter toggle
        self.select().on('click', '.btn-toggle-filter', function () {
            if (self.select('.container-filter').is(':visible')) {
                self.select('.container-filter').removeClass("show-filters");
            } else {
                self.select('.container-filter').addClass("show-filters");
            }
        });

        //handle brands & conditions
        self.select().on('change', '.checkbox-filter', function () {
            //get id & type
            var id = parseInt(self.select(this).val());
            var type = self.select(this).attr('data-type');

            //get array
            var array;
            if (type === "brand") {
                array = self._brands;
            } else if (type === "condition") {
                array = self._conditions;
            }

            //update array
            if (self.select(this).is(":checked")) {
                //add item to array
                array.push(id);
            } else {
                //remote item form array
                var index = array.indexOf(id);
                array.splice(index, 1);
            }

            //reset page index
            self._page = 0;

            //load items
            _loadItems(self);
        });

        //handle sizes
        self.select().on('click', '.size', function () {
            //get id
            var id = parseInt(self.select(this).attr('data-id'));

            //get array
            var array = self._sizes;

            if (self.select(this).hasClass('swatche-selected')) {
                //remove class
                self.select(this).removeClass('swatche-selected');

                //remote item form array
                var index = array.indexOf(id);
                array.splice(index, 1);
            } else {
                //add class
                self.select(this).addClass('swatche-selected');

                //add item to array
                array.push(id);
            }

            //reset page index
            self._page = 0;

            //load items
            _loadItems(self);
        });

        //handle colors
        self.select().on('click', '.color', function () {
            //get id
            var id = parseInt(self.select(this).attr('data-id'));

            //get attribute array
            var array = self._colors;

            if (self.select(this).hasClass('swatche-selected')) {
                //remove class
                self.select(this).removeClass('swatche-selected');

                //remote item form array
                var index = array.indexOf(id);
                array.splice(index, 1);
            } else {
                //add class
                self.select(this).addClass('swatche-selected');

                //add item to array
                array.push(id);
            }

            //reset page index
            self._page = 0;

            //load items
            _loadItems(self);
        });

        //handle min price
        self.select().on('change', '.min-price', function () {
            //set min price
            var val = parseInt(self.select(this).val());
            if (val) {
                self._minPrice = val;
            } else {
                self._minPrice = null;
            }

            //reset page index
            self._page = 0;

            //load items
            _loadItems(self);
        });

        //handle max price
        self.select().on('change', '.max-price', function () {
            //set max price
            var val = parseInt(self.select(this).val());
            if (val) {
                self._maxPrice = val;
            } else {
                self._maxPrice = null;
            }

            //reset page index
            self._page = 0;

            //load items
            _loadItems(self);
        });

        //handle filter reset
        self.select().on('click', '.btn-reset', function () {
            //reset criteria and filter
            _resetCriteria(self);
            _resetFilter(self);

            //reset page index
            self._page = 0;

            //load items
            _loadItems(self);
        });

        //handle order by
        self.select().on('change', '.order-by', function () {
            //set order type
            var orderType = parseInt(self.select(this).val());
            self._orderType = orderType;

            //reset page index
            self._page = 0;

            //load items
            _loadItems(self);
        });

        //handle show sold items
        self.select().on('change', '.checkbox-show-sold-items', function () {
            //set show sold items cookie
            if (self.select(this).is(':checked')) {
                App.Context.setShowSoldItems(true);
            } else {
                App.Context.setShowSoldItems(false);
            }

            //reset page index
            self._page = 0;

            //load items
            _loadItems(self);
        });

        //handle pagination
        self.select().on('click', '.item-pagination .previous-page', function () {
            //prevent users from scrolling beyond the first page
            if (self._page <= 0) {
                self._page = 0;
            } else {
                //decrease page index
                self._page -= 1;
            }

            //get criteria
            var criteria = _getCriteria(self);

            //get url
            var url = _getUrl(self, criteria);

            //load url
            location.href = url;
        });

        self.select().on('click', '.item-pagination .next-page', function () {
            var pagesCount = parseInt(self.select(this).attr('data-pages-count'));

            //prevent users from scrolling beyond the last page
            if (self._page >=  pagesCount - 1) {
                self._page = pagesCount - 1;
            } else {
                //increase page index
                self._page += 1;
            }

            //get criteria
            var criteria = _getCriteria(self);

            //get url
            var url = _getUrl(self, criteria);

            //load url
            location.href = url;
        });

        //handle scroll to items
        self.select().on('click', '.btn-scroll-top', function () {
            _scrollToItems(self);
        });

        //parse url
        var page = App.Url.getParameter('page');
        var orderType = App.Url.getParameter('orderType');
        var category = App.Url.getIdParameter('category');
        var subCategory = App.Url.getParameter('subCategory');
        var brand = App.Url.getParameter('brand');
        var brands = App.Url.getParameter('brands');
        var conditions = App.Url.getParameter('conditions');
        var sizes = App.Url.getParameter('sizes');
        var colors = App.Url.getParameter('colors');
        var name = App.Url.getParameter('name');
        var minPrice = App.Url.getParameter('minPrice');
        var maxPrice = App.Url.getParameter('maxPrice');

        self._page = parseInt(page) ? parseInt(page) : 0;
        self._orderType = parseInt(orderType) ? parseInt(orderType) : 1;
        self._category = parseInt(category) ? parseInt(category) : null,
        self._subCategory = parseInt(subCategory) ? parseInt(subCategory) : null,
        self._brand = parseInt(brand) ? parseInt(brand) : null;
        self._brands = _stringToArray(self, brands),
        self._conditions = _stringToArray(self, conditions),
        self._sizes = _stringToArray(self, sizes),
        self._colors = _stringToArray(self, colors),
        self._name = name;
        self._minPrice = parseInt(minPrice) ? minPrice : null;
        self._maxPrice = parseInt(maxPrice) ? maxPrice : null;
    };

    var _loadItems = function (self) {
        //get criteria
        var criteria = _getCriteria(self);

        //get url
        var url = _getUrl(self, criteria);

        if (history.replaceState) {
            //replace state
            history.replaceState(self, document.title, url);

            //get items
            _getItems(self, criteria);
        } else {
            //load url
            location.href = url;
        }
    };

    var _getItems = function (self, criteria) {
        App.Api.call({
            url: '/store/itemsgrid',
            parameters: JSON.stringify(criteria),
            contentType: "application/json; charset=utf-8",
            resultHandler: function (result) {
                //replace html
                self.select(".items-grid").html(result);

                //lazy load images
                self.select('.img-lazy').lazyload({
                    threshold: 200
                });
            }
        });
    };

    var _scrollToItems = function (self) {
        var top = 0;

        if (self.select('.product-summary').length === 1) {
            top = self.select('.product-summary').parent().position().top;
        } else {
            top = self.select('.alert').parent().parent().parent().position().top;
        }

        $('html,body').scrollTop(top);
    };

    var _getUrl = function (self, criteria) {
        var path = '/store/items';

        if (criteria.category) {
            path += '/' + criteria.category;
        }

        var query = '';

        for (var p in criteria) {
            if (p !== 'category') {
                var value = criteria[p];

                if (value instanceof Array) {
                    value = _arrayToString(self, value);
                }

                if (value) {
                    if (query) {
                        query += '&';
                    } else {
                        query += '?';
                    }

                    query += p + '=' + value;
                }
            }
        }

        return path + query;
    };

    var _arrayToString = function (self, array) {
        var str = '';

        for (var i = 0; i < array.length; i++) {
            if (i > 0) {
                str += ',';
            }

            str += array[i];
        }

        return str;
    };

    var _stringToArray = function (self, str) {
        var array = new Array();

        if (!str) {
            return array;
        } else {
            var parts = str.split(',');

            $.each(parts, function (index, part) {
                var id = parseInt(part);
                if (id) {
                    array.push(id);
                }
            });

            return array;
        }
    };

    var _getCriteria = function (self) {
        return {
            page: self._page,
            orderType: self._orderType,
            category: self._category,
            subCategory: self._subCategory,
            brand: self._brand,
            brands: self._brands,
            conditions: self._conditions,
            sizes: self._sizes,
            colors: self._colors,
            name: self._name,
            minPrice: self._minPrice,
            maxPrice: self._maxPrice
        };
    };

    var _resetCriteria = function (self) {
        self._page = 0;

        self._orderType = 1;

        self._brands = new Array();
        self._conditions = new Array();
        self._sizes = new Array();
        self._colors = new Array();

        self._minPrice = null;
        self._maxPrice = null;   
    };

    var _resetFilter = function (self) {
        self.select('input[type=checkbox]').prop('checked', false);

        self.select('.size').removeClass('swatche-selected');

        self.select('.color').removeClass('swatche-selected');

        self.select('.min-price').val('');
        self.select('.max-price').val('');
    };

    /* Public */
    var self = function (selector) {
        base.call(this, selector);
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);
Class.register('App.Store.Location');
App.Store.Location = (function (base, $) {
    /* Private */
    var _load = function (self) {
        //display map
        displayMap(self);

        //init store carousel
        self.select('.store-carousel').owlCarousel({
            lazyLoad: false,
            loop: true,
            margin: 8,
            responsive: {
                0: {
                    items: 1,
                    slideBy: 1,
                    nav: false
                },
                768: {
                    items: 2,
                    slideBy: 2,
                    nav: false
                },
                992: {
                    items: 3,
                    slideBy: 3,
                    nav: true
                }
            }
        });
    };

    /*
    var displayMap = function (self) {
        //get the map container
        var mapContainerElement = document.getElementsByClassName('map')[0];

        //create map
        var map = new google.maps.Map(mapContainerElement, {
            center: { lat: 41.321720, lng: 19.814102 },
            zoom: 16,
            mapTypeControlOptions: {
                mapTypeIds: [
                    'roadmap',
                    'satellite'
                ],
                position: google.maps.ControlPosition.BOTTOM_LEFT
            }
        });

        //create info window
        var infoWindow = new google.maps.InfoWindow({
            content: App.Context.getResource('<strong>ViaAnabel</strong><br><span>Rruga Brigada e VIII</span><br><span>Pallati TeknoProjekt Kati I</span><br><span>Tiranë, Shqipëri</span>',
                '<strong>ViaAnabel</strong><br><span>Brigada VIII Street<span><br><span>TecnoProject Building First Floor</span><br><span>Tirana, Albania</span>')
        });

        //add marker
        var marker = new google.maps.Marker({
            position: { lat: 41.321720, lng: 19.814102 },
            map: map,
            title: 'ViaAnabel'
        });

        //add info window on marker click
        marker.addListener("click", function () {
            infoWindow.open(map, marker);
        });
    };
    */

    /*
        Rewrote displayMap function due to changes in the Goggle Maps JavaScript API, which has been rewritten
        to use async and await. GulpUglify doesn't work with async and await, therefore the displayMap has
        been rewritten with simple Promise handling. The Google Maps Key and Map ID have been configured
        in the Google Cloud Console.
    */
    var displayMap = function (self) {
        google.maps.importLibrary("maps").then(function (mapApi) {
            //create map
            var map = new mapApi.Map(document.getElementById("map"), {
                mapId: '9dcde6d68a7c40b9',
                center: { lat: 41.321720, lng: 19.814102 },
                zoom: 16
            });

            //create info window
            var infoWindow = new mapApi.InfoWindow({
                content: App.Context.getResource('<strong>ViaAnabel</strong><br><span>Rruga Brigada e VIII</span><br><span>Pallati TeknoProjekt Kati I</span><br><span>Tiranë, Shqipëri</span>',
                    '<strong>ViaAnabel</strong><br><span>Brigada VIII Street<span><br><span>TecnoProject Building First Floor</span><br><span>Tirana, Albania</span>')
            });

            //create marker
            google.maps.importLibrary("marker").then(function (markerApi) {
                var marker = new markerApi.AdvancedMarkerElement({
                    map: map,
                    position: { lat: 41.321720, lng: 19.814102 },
                    title: 'ViaAnabel'
                });

                marker.addListener("click", function () { 
                    infoWindow.open(map, marker);
                });
            });
        });
    }

    /* Public */
    var self = function (selector) {
        base.call(this, selector);
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);
Class.register('App.Store.OrderConfirmation');
App.Store.OrderConfirmation = (function (base, $) {
    /* Private */
    var _load = function (self) {
        var paymentMethod = App.Context.getPaymentMethod();

        App.Context.setShippingOption(null);
        App.Context.setShippingAddress(null);
        App.Context.setPaymentMethod(null);

        var gtag = App.Lib.getGTag();
        if (gtag) {
            if (paymentMethod === App.PaymentMethod.PayPal) {
                gtag('event', 'user_submitted_paypal_payment');
            }

            gtag('event', 'user_completed_order');
        }
    };

    /* Public */
    var self = function (selector) {
        base.call(this, selector);
        
        //check that the order number is still valid
        if (!App.Context.getOrderNumber()) {
            location.href = '/store/cart';
        }
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);
Class.register('App.Store.PaymentMethods');
App.Store.PaymentMethods = (function (base, $) {
    /* Private */
    var _load = function (self) {
        self.select('.btn-payment-method').click(function () {
            if (!App.Context.getSessionId()) {
                alert(App.Context.getResource('Ju kemi fshirë ose çaktivizuar cookies. Ju lutem vendosini artikujt në shportë nga e para.', 'You have deleted or disabled cookies. Please add the items to the shopping cart once more.'));
            } else {
                var id = self.select(this).attr('data-payment-method');

                App.Context.setPaymentMethod(parseInt(id));

                _submitOrder(self);
            }
        });
    };


    var _submitOrder = function (self) {
        self.select('.order-form .notes').notes('clear');

        var errors = new Array();

        var shippingOption = App.Context.getShippingOption();
        var shippingAddress = App.Context.getShippingAddress();
        var paymentMethod = App.Context.getPaymentMethod();

        App.Api.call({
            url: '/api/store/salesorder/submit',
            parameters: {
                firstName: shippingAddress.firstName,
                lastName: shippingAddress.lastName,
                phone: shippingAddress.phone,
                email: shippingAddress.email,
                recipientFirstName: shippingAddress.firstName,
                recipientLastName: shippingAddress.lastName,
                recipientPhone: shippingAddress.phone,
                street1: shippingAddress.street1,
                street2: shippingAddress.street2,
                city: shippingAddress.city,
                stateOrProvince: shippingAddress.stateOrProvince,
                postalCode: shippingAddress.postalCode,
                country: shippingAddress.country,
                subscribeEmail: shippingAddress.subscribeEmail,
                shippingOption: shippingOption,
                paymentMethod: paymentMethod
            },
            resultHandler: function (result) {
                if (!result.OrderNumber) {
                    errors.push(App.Context.getResource('Disa nga artikujt në shportë sapo janë rezervuar ose shitur. Ju do ri-dërgoheni tek shporta për ta përditësuar.', 'Some of the items on the cart have been reserved or sold. You will be sent to the shopping cart to update the list of items.'));

                    self.select('.order-form .notes').notes('confirm', errors);

                    setTimeout(function () {
                        location.href = '/store/cart';
                    }, 5000);
                } else {
                    App.Context.setOrderNumber(result.OrderNumber);

                    location.href = '/store/orderconfirmation';
                }
            }
        });
    };

    /* Public */
    var self = function (selector) {
        base.call(this, selector);
        
        //check session id
        if (!App.Context.getSessionId()) {
            location.href = '/store/cart';
        }

        //check that the shipping option has been selected and shipping address has been entered
        if (!App.Context.getShippingOption() || !App.Context.getShippingAddress()) {
            location.href = '/store/cart';
        }

        //check that the cart is not empty
        if (!App.Context.getCartCount()) {
            location.href = '/store/cart';
        }
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);
/* 
 * Implementation of the PayPal JavaScript SDK and Rest API (version 2) 
 */
App.Store.PayPal = (function (base, $) {
    /* Private */
    var _load = function (self) {
        //check if paypal button container exists
        if (self.select().length === 1) {
            var items = new Array();
            var total = 0;
            var subTotal = 0;
            var discount = 0;
            var shippingTotal = self._shippingTotal;

            //add items
            $.each(self._items, function (index, item) {
                items.push({
                    sku: item.Sku,
                    name: item.BrandName ? item.BrandName + ' - ' + item.Name : item.Name,            
                    quantity: 1,
                    unit_amount: {
                        currency_code: 'EUR',
                        value: item.Price
                    }
                });

                //update sub total
                subTotal += item.Price;
            });

            //update total
            total = subTotal + shippingTotal - discount;
            
            //For additional information on how to create and capture an order with the PayPal JavaScript SDK
            //refer to the API documentation https://developer.paypal.com/docs/api/orders/v2
            //and the JavaScript SDK reference https://developer.paypal.com/sdk/js/reference.
            //Note that the application_context (deprecated) has been replaced with experience_context (see API Docs).
            //In both cases shipping_preference has been set to NO_SHIPPING because the shipping address has been 
            //already entered by the user.You may want to revert to the application_context if the experience_context 
            //doesn't work properly.

            //create paypal buttons
            paypal.Buttons({
                style: {
                    color: 'blue'
                },
                createOrder: function (data, actions) {
                    return actions.order.create({
                        /*
                        application_context: {
                            shipping_preference: 'NO_SHIPPING'
                        },
                        */
                        payment_source: {
                            paypal: {
                                experience_context: {
                                    shipping_preference: 'NO_SHIPPING'
                                }
                            }
                        },
                        purchase_units: [{
                            amount: {
                                currency_code: 'EUR',
                                value: total,
                                breakdown: {
                                    item_total: {
                                        currency_code: 'EUR',
                                        value: subTotal
                                    },
                                    shipping: {
                                        currency_code: 'EUR',
                                        value: shippingTotal
                                    },
                                    discount: {
                                        currency_code: 'EUR',
                                        value: discount
                                    }
                                }
                            },
                            items: items
                        }]
                    });
                },
                onApprove: function (data, actions) {
                    //capture paypal order
                    return actions.order.capture().then(function (response) {
                        //check capture status
                        if (response.status === 'COMPLETED') {
                            //get shipping option and shipping address
                            var shippingOption = App.Context.getShippingOption();
                            var shippingAddress = App.Context.getShippingAddress();

                            //set payment method
                            App.Context.setPaymentMethod(App.PaymentMethod.PayPal);
                        
                            //submit sales order
                            App.Api.call({
                                url: '/api/store/salesorder/submit',
                                parameters: {
                                    firstName: shippingAddress.firstName,
                                    lastName: shippingAddress.lastName,
                                    phone: shippingAddress.phone,
                                    email: !App.Validator.isEmpty(shippingAddress.email) ? shippingAddress.email : response.payer.email_address,
                                    recipientFirstName: shippingAddress.firstName,
                                    recipientLastName: shippingAddress.lastName,
                                    recipientPhone: shippingAddress.phone,
                                    street1: shippingAddress.street1,
                                    street2: shippingAddress.street2,
                                    city: shippingAddress.city,
                                    stateOrProvince: shippingAddress.stateOrProvince,
                                    postalCode: shippingAddress.postalCode,
                                    country: shippingAddress.country,
                                    subscribeEmail: !App.Validator.isEmpty(shippingAddress.email) ? shippingAddress.subscribeEmail : false,
                                    shippingOption: shippingOption,
                                    paymentMethod: App.PaymentMethod.PayPal
                                },
                                resultHandler: function (result) {
                                    //go to the refund page if the sales order was submitted but couldn't be completed 
                                    //because at least one of the items on the cart has been reserved or sold
                                    if (!result.OrderNumber) {
                                        location.href = '/store/paypalrefund';
                                    } else {
                                        App.Context.setOrderNumber(result.OrderNumber);

                                        location.href = '/store/orderconfirmation';
                                    }
                                }
                            });
                        } else {
                            //set message
                            var message = 'The payment was not completed';

                            //send bugsnag error
                            var bugsnag = App.Lib.getBugsnag();
                            if (bugsnag) {
                                bugsnag.notify(new Error(message), function (event) {
                                    event.severity = 'warning';
                                    event.addMetadata('custom', {
                                        type: 'paypal',
                                        stage: 'capture',
                                        message: message,
                                        intent: response.intent,
                                        status: response.status,
                                        payerEmail: response.payer.email_address,
                                        firstName: shippingAddress.firstName,
                                        lastName: shippingAddress.lastName,
                                        phone: shippingAddress.phone,
                                        email: shippingAddress.email
                                    });
                                });
                            }

                            location.href = '/store/paypalpaymentfailure';
                        }
                    });
                },
                onError: function (error) {
                    //get error message
                    var message;
                    if (typeof error === 'string' || error instanceof String) {
                        message = error;
                    }
                    if (typeof error === 'object' && error instanceof Error) {
                        message = error.message;
                    }

                    //send bugsnag error
                    var bugsnag = App.Lib.getBugsnag();
                    if (bugsnag) {
                        bugsnag.notify(new Error(message), function (event) {
                            event.severity = 'error';
                            event.addMetadata('custom', {
                                type: 'paypal',
                                stage: 'init',
                                message: message,
                                firstName: shippingAddress.firstName,
                                lastName: shippingAddress.lastName,
                                phone: shippingAddress.phone,
                                email: shippingAddress.email
                            });
                        });
                    }
                }
            }).render(self._selector);
        }
    };

    /* Public */
    var self = function (selector, data) {
        base.call(this, selector);

        var jsonData = JSON.parse(data);
        this._items = jsonData.Items;
        this._shippingTotal = jsonData.ShippingTotal;
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);
Class.register('App.Store.ReservationConfirmation');
App.Store.ReservationConfirmation = (function (base, $) {
    /* Private */
    var _load = function (self) {
        App.Context.setShippingOption(null);
        App.Context.setShippingAddress(null);
        App.Context.setPaymentMethod(null);

        var gtag = App.Lib.getGTag();
        if (gtag) {
            gtag('event', 'user_completed_reservation');
        }
    };

    /* Public */
    var self = function (selector) {
        base.call(this, selector);

        //check that the order number is still valid
        if (!App.Context.getOrderNumber()) {
            location.href = '/store/cart';
        }
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);
Class.register('App.Store.ShippingAddress');
App.Store.ShippingAddress = (function (base, $) {
    /* Private */
    var _load = function (self) {
        self.select('.btn-order').click(function () {
            if (!App.Context.getSessionId()) {
                alert(App.Context.getResource('Ju kemi fshirë ose çaktivizuar cookies. Ju lutem vendosini artikujt në shportë nga e para.', 'You have deleted or disabled cookies. Please add the items to the shopping cart once more.'));
            } else {
                _saveShippingAddress(self);  
            }
        });

        self.select('.link-phone-prefixes').click(function () {
            self.select('.modal-phone-prefixes').modal();
        });

        _initShippingAddress(self); 
    };

    var _saveShippingAddress = function (self) {
        self.select('.shipping-address .notes').notes('clear');

        var errors = new Array();

        var shippingAddress = _getShippingAddress(self);

        var validator = App.Validator;

        if (validator.isEmpty(shippingAddress.firstName)) {
            errors.push(App.Context.getResource('Emri duhet vendosur.', 'First name is required.'));
        }

        if (validator.isEmpty(shippingAddress.lastName)) {
            errors.push(App.Context.getResource('Mbiemri duhet vendosur.', 'Last name is required.'));
        }

        if (validator.isEmpty(shippingAddress.phone)) {
            errors.push(App.Context.getResource('Telefoni duhet vendosur.', 'Phone is required.'));
        } else if (!validator.isPhone(shippingAddress.phone)) {
            errors.push(App.Context.getResource('Telefoni nuk është në formatin e duhur. Ju lutem përdorni numrat (0-9) për të vendosur numrin e telefonit. Për numrat ndërkombëtar mund të përdorni + para kodit të shtetit.', 'The phone format is invalid. Please use digits (0-9) to enter the phone number. For international numbers you can use + before the country code.'));
        }

        if (!validator.isEmpty(shippingAddress.email) && !validator.isEmail(shippingAddress.email)) {
            errors.push(App.Context.getResource('Formati i email-it është i gabuar.', 'The email address must be valid.'));
        }

        if (validator.isEmpty(shippingAddress.street1)) {
            errors.push(App.Context.getResource('Rruga duhet vendosur.', 'Street is required.'));
        }

        if (validator.isEmpty(shippingAddress.city)) {
            errors.push(App.Context.getResource('Qyteti duhet vendosur.', 'City is required.'));
        }

        if (validator.isEmpty(shippingAddress.country)) {
            errors.push(App.Context.getResource('Shteti duhet vendosur.', 'Country is required.'));
        }

        if (errors.length) {
            self.select('.shipping-address .notes').notes('confirm', errors);

            $('html,body').scrollTop($('#main-container').position().top);
        } else {
            //get shipping option
            var shippingOption = App.Context.getShippingOption();

            //set shipping address
            App.Context.setShippingAddress(shippingAddress);

            //save shipping option and shipping address
            if (App.Storage.isAvailable()) {
                App.Storage.set('ShippingOption', shippingOption.toString());

                App.Storage.set('FirstName', shippingAddress.firstName);
                App.Storage.set('LastName', shippingAddress.lastName);
                App.Storage.set('Phone', shippingAddress.phone);
                App.Storage.set('Email', shippingAddress.email);
                App.Storage.set('Street1', shippingAddress.street1);
                App.Storage.set('Street2', shippingAddress.street2);
                App.Storage.set('City', shippingAddress.city);
                App.Storage.set('StateOrProvince', shippingAddress.stateOrProvince);
                App.Storage.set('PostalCode', shippingAddress.postalCode);
                App.Storage.set('Country', shippingAddress.country);
            }

            location.href = '/store/paymentmethods';
        }
    };

    var _initShippingAddress = function (self) {
        var shippingOption = App.Context.getShippingOption();

        if (shippingOption === App.ShippingOption.TiranaAlbania) {
            self.select('.shipping-address .city').val(App.Context.getResource('Tiranë', 'Tirana')).attr('disabled', 'disabled');
            self.select('.shipping-address .country').val(App.Context.getResource('Shqipëri', 'Albania')).attr('disabled', 'disabled');
        } else if (shippingOption === App.ShippingOption.OtherCityAlbania) {
            self.select('.shipping-address .country').val(App.Context.getResource('Shqipëri', 'Albania')).attr('disabled', 'disabled');
        } else if (shippingOption === App.ShippingOption.Kosovo) {
            self.select('.shipping-address .country').val(App.Context.getResource('Kosovë', 'Kosovo')).attr('disabled', 'disabled');
        } else {
            self.select('.shipping-address .state-province-group').show();
            self.select('.shipping-address .postal-code-group').show();
        }

        if (App.Storage.isAvailable()) {
            self.select('.shipping-address .first-name').val(App.Storage.get('FirstName'));
            self.select('.shipping-address .last-name').val(App.Storage.get('LastName'));
            self.select('.shipping-address .phone').val(App.Storage.get("Phone"));
            self.select('.shipping-address .email').val(App.Storage.get("Email"));
           
            //populate address data only if shipping option is the same
            if (parseInt(App.Storage.get("ShippingOption")) === shippingOption) {
                self.select('.shipping-address .street-1').val(App.Storage.get("Street1"));
                self.select('.shipping-address .street-2').val(App.Storage.get("Street2"));
                self.select('.shipping-address .city').val(App.Storage.get("City"));
                self.select('.shipping-address .state-province').val(App.Storage.get("StateOrProvince"));
                self.select('.shipping-address .postal-code').val(App.Storage.get("PostalCode"));
                self.select('.shipping-address .country').val(App.Storage.get("Country"));
            }
        }
    };

    var _getShippingAddress = function (self) {
        return {
            firstName: self.select('.shipping-address .first-name').val().trim(),
            lastName: self.select('.shipping-address .last-name').val().trim(),
            phone: self.select('.shipping-address .phone').val().trim().replace(/\s/g, ''),
            email: self.select('.shipping-address .email').val().trim().toLowerCase(),
            subscribeEmail: self.select('.subscribe-email').is(':checked'),
            street1: self.select('.shipping-address .street-1').val().trim(),
            street2: self.select('.shipping-address .street-2').val().trim(),
            city: self.select('.shipping-address .city').val().trim(),
            stateOrProvince: self.select('.shipping-address .state-province').val().trim(),
            postalCode: self.select('.shipping-address .postal-code').val().trim(),
            country: self.select('.shipping-address .country').val().trim()
        };
    };

    /* Public */
    var self = function (selector) {
        base.call(this, selector);

        //check session id
        if (!App.Context.getSessionId()) {
            location.href = '/store/cart'; 
        }

        //check that the shipping option has been selected
        if (!App.Context.getShippingOption()) {
            location.href = '/store/cart';
        }

        //check that the cart is not empty
        if (!App.Context.getCartCount()) {
            location.href = '/store/cart';
        }
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);
Class.register('App.Store.ShippingOptions');
App.Store.ShippingOptions = (function (base, $) {
    /* Private */
    var _load = function (self) {
        self.select('.btn-shipping-option').click(function () {
            if (!App.Context.getSessionId()) {
                alert(App.Context.getResource('Ju kemi fshirë ose çaktivizuar cookies. Ju lutem vendosini artikujt në shportë nga e para.', 'You have deleted or disabled cookies. Please add the items to the shopping cart once more.'));
            } else {
                var id = self.select(this).attr('data-shipping-option');

                App.Context.setShippingOption(parseInt(id));

                location.href = '/store/shippingaddress';
            }        
        });
    };
 
    /* Public */
    var self = function (selector) {
        base.call(this, selector);

        //check session id
        if (!App.Context.getSessionId()) {
            location.href = '/store/cart';
        }

        //check that the cart is not empty
        if (!App.Context.getCartCount()) {
            location.href = '/store/cart';
        }
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);
Class.register('App.Store.WishList');
App.Store.WishList = (function (base, $) {
    /* Private */
    var _load = function (self) {
        //lazy load images
        self.select('.img-lazy').lazyload({
            threshold: 200
        });

        //delete from wish list
        self.select().on('click', '.btn-delete', function () {
            if (!App.Context.getSessionId()) {
                alert(App.Context.getResource('Cookies duhen aktivizuar për të përditësuar wish list.', 'Cookies must be enabled to update the wish list.'));
            } else {
                var id = parseInt(self.select(this).attr('data-id'));
                _deleteItem(self, id);
            }
        });
    };

    var _loadItems = function (self) {
        App.Api.call({
            url: '/store/wishlistgrid',
            resultHandler: function (result) {
                //load html
                self.select('.wish-list-grid').html(result);

                //lazy load images
                self.select('.img-lazy').lazyload({
                    threshold: 200
                });

                //scroll to top
                $('html,body').scrollTop($('#main-container').position().top);
            }
        });
    };

    var _deleteItem = function (self, id) {
        App.Api.call({
            url: '/api/store/wishlist/item/delete',
            parameters: {
                itemId: id
            },
            resultHandler: function (result) {
                App.EventManager.dispatchEvent('WishListChanged', { Count: result.Count });

                _loadItems(self);

                var gtag = App.Lib.getGTag();
                if (gtag) {
                    gtag('event', 'user_deleted_item_from_wish_list');
                }
            }
        });
    };
  
    /* Public */
    var self = function (selector) {
        base.call(this, selector);
    };

    Class.extend(self, base);

    self.prototype.load = function () {
        base.prototype.load.call(this);
        _load(this);
    };

    self.prototype.destroy = function () {
        base.prototype.destroy.call(this);
    };

    return self;
})(App.View, jQuery);